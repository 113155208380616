<template>
  <div>
    <el-form-item prop="account">
      <el-input v-model.trim="account1" placeholder="用户名" type="text" />
    </el-form-item>
    <el-form-item prop="password">
      <el-input v-model.trim="password1" placeholder="密码" type="password" />
    </el-form-item>
  </div>
</template>
<script>
export default {
  name: 'Account',

  props: {
    account: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      account1: this.$props.account,
      password1: this.$props.password
    }
  },
  watch: {
    account1: {
      handler(val) {
        this.$emit('update:account', val)
      },
    },
    password1: {
      handler(val) {
        this.$emit('update:password', val)
      }
    }
  },
}
</script>
