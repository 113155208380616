/*
 * @Description: 用户api

 * @Date: 2021-09-22 16:22:01

 * @LastEditTime: 2021-09-22 16:27:10
 */

import request from '@/utils/request'

/**
 * 登录
 */
export const login = async (data) => request({ url: "/login/login", method: "POST", data })
// export const login = async (data) => request({ url: "/v1.0.0/clerk/queryClerkPage", method: "POST", data })

/**
 * 注册
 */
export const register = async (data) => request({ url: "/register", method: "POST", data })

// export const shopMemberLogin = (data) => {
//     return request({
//         url: '/login/shopMemberLogin',
//         method: 'GET',
//         data
//     })
// }
export const shopMemberLogin = async (params) => request({ url: "/login/shopMemberLogin", method: "GET", params })