<!--登录-->
<template>
  <div class="login">
    <div class="login-content">
      <div class="login-content-left">

        <div class="flex col-bottom mb40 title">
          <el-avatar :size="60" :src="logoImage"></el-avatar>
        </div>
        <template v-if="stepIndex == 1">
          <el-form :model="loginForm" ref="loginFormRef" :rules="loginFormRules" @keyup.enter="login_WebLogin"
            label-width="0">
            <div v-show="!shopSelect">
              <accountForm :account.sync="loginForm.account" :password.sync="loginForm.password"></accountForm>
              <el-checkbox v-model="isRecord" class="mb10" style="display: flex;align-items: center;">记住密码</el-checkbox>
              <el-button :loading="loginLoading" class="w-100 h48 f18" type="primary"
                @click.prevent="login_WebLogin()">登录</el-button>
            </div>
            <div v-show="shopSelect">
              <el-button class="btn-login-back" size="small" type="primary" link
                @click="shopSelect = !shopSelect">返回</el-button>
              <shopListForm :shopList="shopList" @shopClick="shopClick" />
            </div>
          </el-form>
        </template>
      </div>
      <div class="login-content-right">
        <img class="w-100" :src='logoImage' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { login, shopMemberLogin } from "@/api/user";
import accountForm from './modules/account.vue'
// import shopSelectForm from './modules/shopSelect.vue'
import shopListForm from "./modules/shopList.vue";
import logoImage from "@/assets/image/logo.jpeg";

export default {
  name: "login",

  created() {
    const wordKey = JSON.parse(localStorage.getItem('wordKey'))
    if (wordKey) {
      const { isRecord, account, password } = JSON.parse(localStorage.getItem('wordKey') || {})
      this.loginForm.account = account
      this.loginForm.password = password
      this.isRecord = isRecord
    }
    this.logout();
  },
  components: {
    accountForm,
    // shopSelectForm,
    shopListForm
  },
  data() {
    return {
      isRecord: false,
      logoImage,
      loginLoading: false,
      stepIndex: 1,
      shopSelect: false,
      shopOther: false,
      shopList: [
        {
          shopKey: '',
          shopName: ''
        }
      ],
      loginForm: {
        account: '',
        password: '',
        shopKey: ''
      },
      loginFormRules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      }
    };
  },

  methods: {
    ...mapMutations(["setToken", "setUserInfo", "logout"]),


    clerkLoginCache() { },

    setWordKey() {
      if (this.isRecord) {
        const _data = {
          isRecord: this.isRecord,
          ...this.loginForm
        }
        localStorage.setItem('wordKey', JSON.stringify(_data))
      } else {
        localStorage.removeItem('wordKey')
      }
    },

    shopClick(item) {
      this.loginForm.shopKey = item.shopKey
      shopMemberLogin({ shopKey: item.ShopKey }).then(res => {
        this.$notify({
          title: "登陆成功",
          message: "快去体验可视化给构建商城吧！",
          type: "success",
        });
        this.setToken(res.token);
        this.setUserInfo(res.userInfo);
        this.setWordKey()
        this.$router.push({ name: "manage" });
      })
    },

    // 登录
    async login_WebLogin() {
      this.$refs["loginFormRef"].validate(async (valid) => {
        if (valid) {
          this.loginLoading = true
          let res = await login(this.loginForm);
          console.log(res)
          this.loginLoading = false
          if (res.status == '10002') {
            this.shopList = res.Clerks
            this.shopSelect = true
          } else if (res.status == "10000") {
            this.$notify({
              title: "登陆成功",
              message: "快去体验可视化给构建商城吧！",
              type: "success",
            });
            this.setToken(res.token);
            this.setUserInfo(res.userInfo);
            this.setWordKey()
            this.$router.push({ name: "manage" });
          } else {
            this.$notify({
              title: "登录失败",
              message: res.messsage,
              type: "error",
            });
          }
        }
      });
    },

  },
};
</script>

<style lang="scss" scoped>
:deep(.el-input__inner) {
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 16px;
  border-bottom: solid 1px #dcdee0 !important;
}

:deep(.el-input__icon) {
  font-size: 20px;
}

.login {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #f2f3f5;
  // overflow-y: hidden;
  overflow: auto;

  .login-content {
    display: flex;
    height: 500px;
    margin-top: 200px;

    .login-content-left {
      width: 550px;
      margin-right: 20px;
      border-radius: 6px;
      background: #fff;
      padding: 50px 56px 60px;

      .title {
        justify-content: center;
      }

      .tab {
        font-size: 20px;
        color: $color-grey;
        cursor: pointer;
      }

      // .tab-active {
      //   font-size: 24px;
      //   color: #323233;
      //   font-weight: 500;
      // }
    }

    .login-content-right {
      display: flex;
      align-items: center;
      width: 320px;
      border-radius: 6px;
      background: #fff;
    }
  }
}
</style>
