<template>
  <el-scrollbar style="height: 240px;">
    <!-- <template v-for="item of 10" :key="item"> -->
    <el-row v-for="(item, index) in list" :key="index" class="shop-list-row" align="middle">
      <el-col :span="19">
        <span>{{ item.ShopName }} </span>
      </el-col>
      <el-col :span="3">
        <el-button class="shop-button" size="small" type="primary" @click.prevent="shopClick(item)"> 进 入 </el-button>
      </el-col>
    </el-row>
    <!-- </template> -->
  </el-scrollbar>
</template>
<script>
export default {
  name: "shopList",
  props: ['shopList'],
  data() {
    return {
      list: this.$props.shopList
    }
  },
  methods: {
    shopClick(item) {
      this.$emit('shopClick', item)
    }
  },
  watch: {
    shopList: {
      handler(val) {
        this.list = [...val]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.el-scrollbar__wrap) {
  overflow-x: hidden;
}

.shop-list-row {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
</style>
